<template>
  <LoadingScreen />
</template>

<script>
import handleHead from '@/components/mixins/handleHead'
export default {
  components: {
    LoadingScreen: () => import('@/components/UI/LoadingScreen'),
  },
  mixins: [handleHead],
  layout: 'landing',
  beforeMount() {
    return window.location.replace('/')
  },
}
</script>
