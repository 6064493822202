import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    yearlyPlanOriginalPrice: 1000,
    monthlyPlanOriginalPrice: 100,
    initialDiscount: 1 - 49 / 100,
    trialDiscount: 0,
    cookieVoucherCode: null,
    cookie499Expiry: null,
  }
}

export const state = defaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
