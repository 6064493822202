import setGuestUser from '~/assets/js/setGuestUser'

export default async function ({ app, req, res, store, redirect, query }) {
  if (!process.server) return

  if (!app.$cookies.get('connect.sid'))
    await app.$axios('/api/auth/user', {
      method: 'GET',
    })

  res.clearCookie('connect.sid', { path: '/', domain: '.portaldotrader.com.br' }) // this should be deleted some day in the future...

  const hostname2realm = store.getters['realms/hostname2realm']
  const realm = hostname2realm(req.headers.host)
  store.state.realms.activeRealm = realm

  if (realm === 'pdt') {
    const { pdtSession, standalone } = query
    if (standalone) res.header('Access-Control-Allow-Origin', '*')
    if (!pdtSession) {
      const url = `${req.get('x-forwarded-proto') || req.protocol}://${req.get('host')}${req.originalUrl}`
      return redirect(`https://portaldotrader.com.br/api/cdi-session-redir?url=${encodeURIComponent(url)}`)
    }
    const user = await app
      .$axios(`https://portaldotrader.com.br/api/user/session/${pdtSession}`, {
        method: 'GET',
        headers: {
          authorization: process.env.PDT_SECRET,
        },
      })
      .catch(() => {})
    if (!user?.data) {
      await app.$auth.logout()
      return redirect('https://portaldotrader.com.br')
    } else if (user.data.guest) {
      await app.$auth.logout()
      setGuestUser(app, realm)
    } else {
      const external = await app
        .$axios('/api/auth/external', {
          method: 'GET',
          data: { token: user.data.token },
        })
        .catch(() => {})
      if (external?.data) app.$auth.setUser(external.data)
    }
  } else if (realm === 'inter') {
    const userResponse = await app.$axios.get('/api/auth/user')
    if (!userResponse.data) {
      if (req.path === '/jornada') {
        app.$axios.post('/api/tools/mp/authentication', { type: 'jornada' })
      } else if (req.path === '/login') {
        if (!req.query.token) return redirect('/')
        const validHash = await app.$axios.get(`/api/tools/tokens/${req.query.token}`).catch(() => {})
        if (!validHash) return redirect('/')
        app.$axios.post('/api/tools/mp/authentication', { type: validHash.data.type })
        const token = await app.$axios('/api/auth/inter/token')
        if (!token?.data) return redirect('/')
        const external = await app
          .$axios('/api/auth/external', {
            method: 'GET',
            data: { token: token.data },
          })
          .catch(() => {})
        if (external?.data) app.$auth.setUser(external.data)
        return redirect('/')
      } else {
        await app.$auth.logout()
        setGuestUser(app, realm)
      }
    } else if (userResponse.data && req.path === '/login') return redirect('/')
  }
}
