import moment from 'moment'
import { sleep } from '~/assets/js/utils'
import { setAbCookies } from '~/assets/js/cookies'

export default async function ({ route, app, store }) {
  const realm = store.state.realms.activeRealm
  if (realm === 'cdi') {
    let i = 0
    const interval = 50
    while (!app.$cookies.get('_ga') && i < 1000 && !process.server) {
      i += interval
      await sleep(interval)
    }
  }
  let cookieString = []
  const cookies = []
  if (['parceiro-w', 'parceiro-cupom', 'parceiro'].includes(route.name)) {
    const partnerExists = await app.$axios.get(`/api/user/partner/${route.params.parceiro}`)
    if (partnerExists.data) {
      const lastFrom = route.name === 'parceiro-w' ? `${route.params.parceiro}-wp` : route.params.parceiro
      cookies.push(['lastFrom', lastFrom, { path: '/', maxAge: 60 * 60 }]) // 1 hour
      cookies.push(['lastFromExpiry', moment().add(1, 'h').valueOf(), { path: '/', maxAge: 60 * 60 }]) // 1 hour
    }
  } else if (route.query.from) {
    cookies.push(['lastFrom', route.query.from, { path: '/', maxAge: 60 * 60 }]) // 1 hour
    cookies.push(['lastFromExpiry', moment().add(1, 'h').valueOf(), { path: '/', maxAge: 60 * 60 }]) // 1 hour
  }

  Object.keys(route.query).forEach((queryParameter) => {
    if (!queryParameter.startsWith('utm_')) return
    let parameter = queryParameter.split('utm_')?.[1]
    if (!parameter) return
    parameter = `${parameter[0].toUpperCase()}${parameter.slice(1).toLowerCase()}`
    const existingFirstCookie = app.$cookies.get(`firstUtm${parameter}`)
    const existingSessionCookie = app.$cookies.get(`sessionUtm${parameter}`)
    if (!existingFirstCookie) cookies.push([`firstUtm${parameter}`, route.query[queryParameter], { path: '/', maxAge: 60 * 60 * 24 * 30 }])
    if (!existingSessionCookie) cookies.push([`sessionUtm${parameter}`, route.query[queryParameter], { path: '/', expires: false }])
  })

  setAbCookies(app, store, cookies)

  cookies.forEach((cookie) => {
    app.$cookies.set(...cookie)
    cookieString.push(`${app.$cookies.nodeCookie.serialize(cookie[0], cookie[1])}`)
  })
  cookieString = cookieString.join('; ')
  const mergedHeaders = { ...app.$axios.defaults.headers }
  if (cookieString) mergedHeaders.common.cookie += `; ${cookieString}`
  await app.$axios('/api/tools/mp/page-view', {
    method: 'POST',
    data: { url: route.path, name: route.name, params: route.params, query: route.query },
    ...(cookieString && { headers: mergedHeaders }),
  })
}
