import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    loadedEvents: {},
  }
}

export const state = defaultState

export const getters = { getField }

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  receiveEvents: (state, events) => {
    events.forEach((event) => (state.loadedEvents[event._id] = event))
    state.loadedEvents = { ...state.loadedEvents }
  },
  removeEvent: (state, eventId) => {
    Vue.delete(state.loadedEvents, eventId)
  },
}
