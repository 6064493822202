export default function ({ query }) {
  if (process.client) {
    const { pending: newRoute } = window.$nuxt.$router.history
    const modal = newRoute?.query?.modal
    if (!modal) {
      window.$nuxt.$bvModal.hide('trade_detail')
      window.$nuxt.$bvModal.hide('allocation_detail')
      return
    }
    if (modal === 'trade_detail') {
      const tradeInfo = { tradeId: query.tradeId, portfolioId: query.portfolioId }
      window.$nuxt.$emit('modals-trade-detail', tradeInfo)
    }
    if (modal === 'allocation_detail') {
      const assetInfo = { label: query.symbol, nickname: query.symbol }
      const portfolio = query.portfolioId
      window.$nuxt.$emit('modals-allocation-detail', assetInfo, portfolio)
    }
  }
}
