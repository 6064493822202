export default function ({ req, store }, inject) {
  let activeRealm
  if (process.server) {
    const hostname2realm = store.getters['realms/hostname2realm']
    activeRealm = hostname2realm(req.headers.host)
  } else {
    activeRealm = store.state.realms.activeRealm
  }

  inject('activeRealm', activeRealm)
}
