import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    voucherData: {},
    homeContentByVoucher: {
      PORTAL: {
        headline: 'Aluno do <strong>Portal do Trader</strong> tem condição exclusiva na CDI',
        displayName: 'Portal do Trader',
        influencerPic: false,
        imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,c_scale,q_auto:eco,w_300/cdicombr/tnt/logo-pdt-azul-preto',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: true,
      },
      TNT: {
        headline: 'Aluno do <strong>Plano TNT</strong> tem condição exclusiva na CDI',
        displayName: 'Plano TNT',
        influencerPic: false,
        imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,c_scale,q_auto:eco,w_500/pdt-blog/plano-tnt/logo-plano-tnt.png',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: true,
      },
      MASSARO: {
        headline: 'Seguidores do <strong>André Massaro</strong> tem condição exclusiva na CDI',
        influencerPic: true,
        displayName: 'André Massaro',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.75,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/andre-massaro',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: false,
      },
      HAYSON: {
        headline: 'Seguidores do <strong>Hayson Silva</strong> tem condição exclusiva na CDI',
        influencerPic: true,
        displayName: 'Hayson Silva',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.75,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/hayson-silva',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: false,
      },
      VICTOR: {
        headline: 'Seguidores do <strong>Victor Lentini</strong> tem condição exclusiva na CDI',
        influencerPic: true,
        displayName: 'Victor Lentini',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.6,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/victor-lentini',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: false,
      },
      UPDATE: {
        headline: 'Participantes do <strong>Trader#Update</strong> tem condição exclusiva na CDI',
        influencerPic: false,
        displayName: 'Trader#Update',
        imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,c_thumb,q_auto:eco,w_500/cdicombr/cdi/assets/influencers/t_u',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: true,
      },
      APERAM: {
        headline: 'Parceiros da <strong>Aperam</strong> tem condição exclusiva na CDI',
        displayName: 'Aperam',
        influencerPic: false,
        imgSrc: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,c_thumb,q_auto:eco,w_400/cdicombr/cdi/assets/influencers/aperam',
        cta: 'começar agora',
        customComponent: false,
        fromPDT: false,
      },
      'TNT-COMBO': {
        headline: 'Seu acesso foi liberado em parceria com o <strong>Plano TNT</strong> do <strong>Portal do Trader</strong>',
        displayName: '',
        influencerPic: false,
        imgSrc: '',
        cta: 'começar agora',
        customComponent: true,
        componentName: 'TNTCombo',
        fromPDT: false,
      },
    },
  }
}

export const state = defaultState

export const getters = {
  getField,
  homeVoucherList: (state) => Object.keys(state.homeContentByVoucher),
}

export const actions = {}

export const mutations = {
  updateField,
  setVoucherData(state, voucherData) {
    if (!voucherData) return
    if (Object.keys(state.voucherData).length) state.voucherData = {}
    state.voucherData = voucherData
  },
}
