export default function ({ store, query, redirect }) {
  const backUrl = query?.backUrl
  const redirectPath = backUrl || '/seguindo'
  const isLoggedIn = store.state.auth.loggedIn
  const userName = store.state.auth.name
  const userEmail = store.state.auth.email
  const userWhatsapp = store.state.auth.whatsapp
  const isSubscriber = !!store.state.auth?.user?.subscription?.active
  if (isLoggedIn && (!userEmail || !userName || !userWhatsapp)) return
  if (!isLoggedIn || !isSubscriber) return
  redirect(redirectPath)
}
