export default function ({ route, store, redirect }) {
  const isCdiInstance = store.getters['realms/isCdiInstance']
  const realm = store.state.realms.activeRealm
  if (isCdiInstance()) return
  let forbiddenRoutes
  let redirectPath
  if (['inter'].includes(realm)) {
    forbiddenRoutes = ['minha-assinatura', 'feed']
    redirectPath = '/canais'
  }
  if (['pdt'].includes(realm)) {
    forbiddenRoutes = ['minha-assinatura']
    redirectPath = '/aovivo'
  }
  if (forbiddenRoutes.includes(route.name)) return redirect({ path: redirectPath })
}
