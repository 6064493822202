import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    banner: {
      enabled: false,
    },
  }
}

export const state = defaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  updateBanner(state, banner) {
    const store = this.app.store
    if (!banner || banner.realm !== store.state.realms.activeRealm) return
    state.banner = banner
  },
}
