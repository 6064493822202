import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    notifications: {},
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  receiveNotifications: (state, notifications) => {
    notifications.forEach((notification) => {
      if (!state.notifications[notification.portfolioId]) state.notifications[notification.portfolioId] = []
      state.notifications[notification.portfolioId].push({
        portfolioId: notification.portfolioId,
        tradeId: notification.tradeId,
        event: notification.event,
      })
    })
    state.notifications = { ...state.notifications }
  },
  removeNotifications(state, notification) {
    if (!state.notifications[notification.portfolioId]) return
    if (!notification.tradeId) return this.clearPortfolioNotifications(state, notification.portfolioId)
    const notificationIndex = state.notifications[notification.portfolioId].indexOf(notification.tradeId)
    if (notificationIndex !== -1) state.notifications[notification.portfolioId].splice(notificationIndex, 1)

    state.notifications = { ...state.notifications }
  },
  clearPortfolioNotifications: (state, portfolioId) => {
    Vue.set(state.notifications, portfolioId, [])
  },
  treatNotification: (state, notification) => {
    if (!state.notifications[notification.portfolioId]) return
    if (!notification.tradeId) state.notifications[notification.portfolioId] = []
    const filteredNotifications = state.notifications[notification.portfolioId].filter((storeNotification) => {
      return storeNotification.tradeId !== notification.tradeId
    })
    state.notifications[notification.portfolioId] = filteredNotifications
  },
}
