import resetState from '../assets/js/resetState'

export default function ({ route, app, store, redirect }) {
  const realm = store.state.realms.activeRealm
  if (realm === 'inter') return
  if (!store.state.auth.loggedIn) {
    resetState(store)
    return redirect('/checkout', { backUrl: route.fullPath })
  }
}
