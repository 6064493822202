import { getField, updateField } from 'vuex-map-fields'

export const getters = {
  getField,
  hat:
    (state, getters, rootState) =>
    (selectedHat, rooms = []) => {
      const userHat = state.user.hats[selectedHat]
      if (!userHat) return false
      if (typeof userHat === 'boolean') return userHat
      if (!rooms.length) {
        const activeRoom = rootState.rooms.activeRoom
        rooms = [activeRoom]
      } else if (rooms[0] === '*') {
        rooms = rootState.rooms.rooms[rootState.realms.activeRealm].map((r) => r._id)
      }
      return rooms.some((room) => userHat.includes(room))
    },
}

export const actions = {}

export const mutations = {
  updateField,
  addUserPortfolio: (state, id) => {
    const inUserPortfolio = state.user.portfolios.following.find((followedId) => followedId === id)
    if (!inUserPortfolio) state.user.portfolios.following.push(id)
  },
  removeUserPortfolio(state, id) {
    state.user.portfolios.following = state.user.portfolios.following.filter((followedId) => followedId !== id)
    this.app.store.commit('notifications/clearPortfolioNotifications', id)
  },
}
