import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    user: {},
  }
}

export const state = defaultState

export const getters = {
  getField,
  nickname: (state) => (state.user.chat ? state.user.chat.nickname : undefined),
  name: (state) => (state.user.auth ? state.user.auth.name : undefined),
  email: (state) => (state.user.auth ? state.user.auth.email : undefined),
  whatsapp: (state) => (state.user.auth ? state.user.auth.whatsapp : undefined),
  userId: (state) => (state.user ? state.user._id : undefined),
  silencedUntil: (state) => (state.user ? state.user.silencedUntil : undefined),
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
