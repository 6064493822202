import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    activeItem: 0,
    topics: [
      {
        cta: 'Onde encontro as carteiras?',
        animationData: 'https://res.cloudinary.com/portal-do-trader/image/upload/cdicombr/cdi/assets/animations/cdi_carteira_high.webp',
        label: 'CDI',
        icon: ['fal', 'wallet'],
        done: true,
        active: true,
      },
      {
        cta: 'Quais carteiras devo seguir?',
        animationData: 'https://res.cloudinary.com/portal-do-trader/image/upload/cdicombr/cdi/assets/animations/mbl_ns_high.webp',
        label: 'Carteiras Recom.',
        icon: ['fas', 'bars'],
        done: false,
        active: false,
      },
      {
        cta: 'Acompanhe o que está rolando nas Carteiras',
        animationData: 'https://res.cloudinary.com/portal-do-trader/image/upload/cdicombr/cdi/assets/animations/laminas_high.webp',
        label: 'Visualizar Carteira',
        icon: ['fal', 'wallet'],
        done: false,
        active: false,
      },
      {
        cta: 'Quero participar das reuniões',
        animationData: 'https://res.cloudinary.com/portal-do-trader/image/upload/cdicombr/cdi/assets/animations/feed_high.webp',
        label: 'Feed',
        icon: ['fal', 'stream'],
        done: false,
        active: false,
      },
      {
        cta: 'Quero usar o WhatsApp',
        animationData: 'https://res.cloudinary.com/portal-do-trader/image/upload/cdicombr/cdi/assets/animations/aovivo_high.webp',
        label: 'Ao Vivo',
        icon: ['fal', 'signal-stream'],
        done: false,
        active: false,
      },
      {
        cta: 'Vamos cuidar da sua carteira, investidor?',
        animationData: 'https://res.cloudinary.com/portal-do-trader/image/upload/cdicombr/cdi/assets/animations/whatsapp_high.webp',
        label: 'WhatsApp',
        icon: ['fab', 'whatsapp'],
        done: false,
        active: false,
      },
    ],
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

export const mutations = {
  updateField,
  setActiveItem(state, index) {
    if (!index) return
    state.activeItem = index
  },
}
