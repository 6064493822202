export default async function ({ app }) {
  if (!process.server) return

  const flushRes = await app.$axios.get('/api/hydrate/flush')
  if (!flushRes.data) return
  const flush = flushRes.data
  app.store.state.subscription = { ...app.store.state.subscription, ...flush.subscription }
  app.store.state.interPdtConnectionWindow = flush.interPdtConnectionWindow
  if (flush.user) app.$auth.setUser(flush.user)
  app.store.state.portfolios.preloadedPortfolios = flush.portfolios
  app.store.state.trades.preloadedTrades = flush.activeTrades
  Object.values(flush.quotes).forEach((quote) => {
    app.store.state.trades.quotes[quote.trade.symbol] = quote.close
    app.store.state.trades.quoteAsset[quote.trade.symbol] = quote.trade.quoteAsset
  })
  app.store.commit('notifications/receiveNotifications', flush.unreadNotifications)
  app.store.commit('ao_vivo/chat/receiveVotes', flush.dayVotes)
  flush.messages.forEach((messages) => app.store.commit('ao_vivo/chat/receiveMessages', messages))
  app.store.commit('banner/updateBanner', flush.banner)
  app.store.commit('ao_vivo/stream/updateStreams', flush.streams)
}
