import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    title: null,
    state: 'ready',
    expanded: false,
    localPortfolioId: null,
    externalMode: 'url',
    externalLink: null,
    hasPDFAttached: null,
    text: '',
    customTypeText: '',
    customDate: '',
    subjectiveType: 'market-analysis',
    updateMode: null,
    headlineOutput: null,
    selectedPortfolioList: [],
    selectedAuthorsList: [],
    subjectiveTypeOptions: [],
    errorFeedback: '',
    validReplacementText: null,
    optionsByRealm: {
      cdi: ['asset-profile', 'update', 'live-meeting', 'market-analysis'],
      pdt: ['asset-profile', 'update', 'live-meeting', 'market-analysis'],
      inter: ['update', 'market-analysis'],
    },
    socketSetup: {
      'thesis-mode': {
        required: ['tradeId', 'thesis'],
        route: 'request-upsert-trade-thesis',
        type: 'thesis',
      },
      'new-update': {
        required: null,
        route: 'post-feed',
        type: 'update',
      },
      'edit-update': {
        required: null,
        route: 'update-feed',
        type: 'update',
      },
    },
    updateRequirements: ['portfolio', 'authors', 'text', 'type', 'title', 'url', 'subjectiveType', '_id'],
    subjectiveTypeData: {
      'asset-profile': {
        text: '*$VALE3*: Perfil da Empresa',
        replacement: 'customTypeText',
        feedback: 'Insira o assunto abaixo',
        tag: 'Perfil de Ativo',
        required: true,
      },
      update: {
        text: 'Update: *Distribuição de Proventos*',
        replacement: 'customTypeText',
        feedback: null,
        tag: 'Update/Notícia',
        required: false,
      },
      'live-meeting': {
        text: '*Carteira Growth*: Reunião Ao vivo',
        replacement: 'selectedPortfolioList',
        feedback: 'Selecione um portfolio abaixo',
        tag: 'Reunião de Carteira',
        required: true,
      },
      'market-analysis': {
        text: 'Mercado ao Vivo - *Day Trade e Swing Trade*',
        replacement: 'customDate',
        feedback: 'Este update depende de uma data. Selecione abaixo',
        tag: 'Mercado ao Vivo',
        required: true,
      },
    },
    tradeId: null,
    event: null,
    mode: null,
    updatedTrade: null,
    videoId: null,
  }
}

export const state = () => defaultState()

export const getters = { getField }

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
