export default () => {
  if (process.env.NODE_ENV !== 'production') return

  // <!-- Global site tag (gtag.js) - Google Analytics -->
  const script = document.createElement('script')
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-1PCWJ2Y2Z7'
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(script)
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())

  gtag('config', 'G-1PCWJ2Y2Z7')
}
