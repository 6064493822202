<template>
  <nuxt />
</template>

<script>
import handleHead from '@/components/mixins/handleHead'

export default {
  name: 'HomeLayout',
  mixins: [handleHead],
}
</script>

<style lang="scss">
html,
body {
  font-family: $cdi-font-headings;
  background-color: #fcfcfc;
  @include scroll-bar(7px);
}
.btn-accent {
  @include small-cdi-component;
  border-radius: 3px;
  border: none;
  color: white !important;
  // min-width: 360px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $text-bolder !important;
  &:hover {
    color: white !important;
    background-color: darken($cdi-primary-accent, 10);
  }
}
</style>
