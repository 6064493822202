import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    displayingContent: 'chat',
  }
}

export const state = defaultState

export const getters = { getField }

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  setNewContent(store, id) {
    store.displayingContent = id
  },
}
