export default function ({ store, route }) {
  window.onNuxtReady(() => {
    const realm = store.state.realms.activeRealm
    if (realm === 'pdt') {
      const { pdtSession, ...query } = route.query
      const querystring = new URLSearchParams(query)
      querystring.delete('pdtSession')
      const qs = querystring.toString()
      history.replaceState({}, '', `${route.path}${qs ? '?' : ''}${qs}`)
    }
  })
}
