import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    updateVideoId: null,
    videoTitle: null,
    loadedFloatingVideo: false,
    expanded: false,
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
