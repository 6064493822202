import Router from 'vue-router'

const realms = ['inter', 'pdt', 'root-domain']
const realmDomains = {
  'pdt.cdi.com.br': 'pdt',
  'app.portaldotrader.com.br': 'pdt',
  'inter.cdi.com.br': 'inter',
  'mercadoaovivo.bancointer.com.br': 'inter',
  'smarttinvest.cdi.com.br': 'smarttinvest',
  'cdi.com.br': 'root-domain',
  // ngrok links for testing
  // '98bf-179-110-188-4.ngrok-free.app': 'pdt',
}

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  let routesDirectory = null

  if (process.server && ssrContext && ssrContext.nuxt && ssrContext.req) {
    const req = ssrContext.req
    // get the subdomain from the request host
    if (realmDomains[req.headers.host]) {
      routesDirectory = realmDomains[req.headers.host]
      ssrContext.nuxt.routesDirectory = routesDirectory
    } else {
      const matcher = req.headers.host.match(/^(\w+(-\w+)?)\.(localhost|\w+(-\w+)?)(\.\w+)?/) || [realms[realms.length - 1]]
      routesDirectory = matcher[1] || matcher[0]
      // if the subdomain is not in the list of user provided domains, set the rootdirectory to root - domain given by the user
      routesDirectory = realms.includes(routesDirectory) ? routesDirectory : realms[realms.length - 1]
      // Save to the object that will be sent to the client as inline-script
      ssrContext.nuxt.routesDirectory = routesDirectory
    }
  }
  if (process.client) {
    // Get what we saved on SSR
    if (window.__NUXT__ && window.__NUXT__.routesDirectory) {
      routesDirectory = window.__NUXT__.routesDirectory
    }
  }

  function isUnderDirectory(route, directory) {
    const path = route.path
    if (typeof directory === 'object') {
      const isUnderDir = directory.some((dir) => path === '/' + dir || path.startsWith('/' + dir + '/'))
      return isUnderDir
    } else {
      return path === '/' + directory || path.startsWith('/' + directory + '/')
    }
  }

  let newRoutes = options.routes
  if (routesDirectory) {
    const overrideRoutes = options.routes
      .filter((route) => route.name.startsWith(`${routesDirectory}`))
      .map((route) => {
        if (route.name === routesDirectory) return 'index'
        return route.name.replace(new RegExp(`^${routesDirectory}-+`), '')
      })
    newRoutes = options.routes
      .filter((route) => {
        // remove routes from other directories
        if (overrideRoutes.includes(route.name)) return false
        const toRemove = realms.filter((domain) => {
          return domain !== routesDirectory
        })
        return !isUnderDirectory(route, toRemove)
      })
      .map((route) => {
        // remove directory from path and route-name
        if (isUnderDirectory(route, routesDirectory)) {
          return {
            ...route,
            path: route.path.substr(routesDirectory.length + 1) || '/',
            name: route.name.substr(routesDirectory.length + 1) || 'index',
          }
        }
        return route
      })
  }

  return new Router({
    ...options,
    routes: newRoutes,
  })
}
