export default function (context) {
  const abFroms = ['gads-search-carteiras-recomendadas', 'gads-search-recomendacoes-ativas']
  let gadsExperience = context.$cookies.get('gadsExperience')
  const shouldABTest = abFroms.some((from) => context.route.query?.from?.toString().includes(from))

  if (shouldABTest && !gadsExperience) {
    gadsExperience = Math.random() > 0.5 ? 'gads-home' : 'guest-user'
    context.$cookies.set('gadsExperience', gadsExperience, { path: '/', maxAge: 60 * 60 * 24 * 30 })
  }
  if (gadsExperience === 'guest-user') context.redirect({ name: 'feed' })
}
