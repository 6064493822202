import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    preloadedPortfolios: [],
    portfolioList: {},
    idBySlug: {},
    slugById: {},
    nameBySlug: {},
    portfoliosLoaded: false,
    myPortfolios: [],
    portfoliosShortDescription: {
      'special-picks': 'Carteira de ações que busca combinar o melhor timing gráfico para empresas com bons fundamentos.',
      'growth-portfolio': 'Seleção de empresas com bom potencial de crescimento para médio e longo prazo.',
      'hidden-gems': 'Oportunidades em ações de empresas fora do radar, para investidores mais experientes.',
      'escolhas-da-semana': 'As ações com gráficos e indicadores técnicos mais interessantes para a semana.',
      'day-trade-futuros': 'Operações de curto prazo em Índice e Dólar, combinando gráficos e fluxo de negócios.',
      'day-trade-acoes': 'Oportunidades em ações com base em indicadores técnicos e gráficos de curto prazo, ideal para traders.',
      'swing-trade': 'Recomendações de compra e venda de curto prazo (poucos dias) baseadas em gráficos e indicadores técnicos.',
      dividendos: 'Seleção de ações da bolsa que combinam solidez em seus fundamentos econômicos com um bom histórico de distribuição de lucros.',
      'small-caps': 'Empresas com menor valor de mercado mas que representam oportunidades de compra a médio e longo prazo.',
      'quality-portfolio': 'Nossa carteira de ações de empresas consolidadas e que possuem bons fluxos de caixa.',
      'fiis-fundos-imobiliarios': 'Escolha de fundos imobiliários negociados na bolsa.',
      'bold-2022': 'Ações com potencial de performance acima da média em 2022 com um toque de ousadia.',
      'crypto-picks': 'Criptomoedas e tokens com projetos interessantes e com potencial de valorização a médio e longo prazo.',
      bdrs: 'Seleção de ativos estrangeiros com bons fundamentos e negociação na bolsa brasileira.',
    },
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  receivePortfolios(state, portfolios) {
    let preloadedPortfoliosGoToGarbage = false
    const store = this.app.store
    if (!portfolios) {
      preloadedPortfoliosGoToGarbage = true
      portfolios = state.preloadedPortfolios
    }
    portfolios.forEach((portfolio) => {
      if (portfolio.room && !Object.keys(portfolio.room).includes(store.state.realms.activeRealm)) return
      if (portfolio.invisiblePortfolio && (!store.state.auth.user || !store.state.auth.user.hats.su)) return
      if (state.portfolioList[portfolio._id] && state.portfolioList[portfolio._id].lastUpdated > portfolio.lastUpdated) return
      if (store.state.auth.user && portfolio.owners.some((owner) => owner._id === store.state.auth.user._id)) {
        if (!state.myPortfolios.includes(portfolio._id)) state.myPortfolios.push(portfolio._id)
      }
      Object.keys(portfolio.currentSymbols).forEach((symbol) => {
        if (store.state.trades.subscribedSymbols[symbol]) return
        store.state.trades.subscribedSymbols[symbol] = 1

        if (this.$cedro) this.$cedro.emit('subscribe', { symbol })
      })
      portfolio.initialEquity = function (selectedFilter) {
        const portfolio = this
        if (!selectedFilter) return portfolio.initialBalance
        const r = portfolio.relevantEquities.find((r) => (typeof selectedFilter === 'string' ? r.name === selectedFilter : r.value[0] === selectedFilter[0]))
        if (!r) return portfolio.initialBalance
        return r.equity
      }
      portfolio.livePerformance = function (selectedFilter) {
        const portfolio = this
        return portfolio.liveEquity / portfolio.initialEquity(selectedFilter) - 1
      }
      portfolio.initialBenchmarkEquity = function (selectedFilter) {
        const portfolio = this
        if (!selectedFilter) return portfolio.initialBalance
        const r = portfolio.relevantEquities.find((r) => (typeof selectedFilter === 'string' ? r.name === selectedFilter : r.value[0] === selectedFilter[0]))
        if (!r) return portfolio.initialBalance
        return r.benchmarkEquity
      }
      portfolio.liveBenchmarkPerformance = function (selectedFilter) {
        const portfolio = this
        return portfolio.benchmarkEquity / portfolio.initialBenchmarkEquity(selectedFilter) - 1
      }
      portfolio.portfolioAlpha = function (selectedFilter) {
        const portfolio = this
        return (
          (portfolio.liveEquity - portfolio.initialEquity(selectedFilter)) / portfolio.initialEquity(selectedFilter) -
          (portfolio.benchmarkEquity - portfolio.initialBenchmarkEquity(selectedFilter)) / portfolio.initialBenchmarkEquity(selectedFilter)
        )
      }
      // if (!portfolio.liveEquity) portfolio.liveEquity = portfolio.equity
      state.portfolioList[portfolio._id] = { ...state.portfolioList[portfolio._id], ...portfolio }
      state.idBySlug[portfolio.portfolioSlug] = portfolio._id
      state.slugById[portfolio._id] = portfolio.portfolioSlug
      state.nameBySlug[portfolio.portfolioSlug] = portfolio.portfolioName
    })
    state.portfolioList = { ...state.portfolioList }
    portfolios.forEach((portfolio) => {
      if (!portfolio.liveEquity) store.commit('portfolios/updateLivePerformance', portfolio._id)
    })

    if (preloadedPortfoliosGoToGarbage) state.preloadedPortfolios = []
  },
  updateLivePerformance(state, portfolioId) {
    if (!state.portfolioList[portfolioId]) return
    const portfolio = state.portfolioList[portfolioId]
    let liveEquity = portfolio.balance
    portfolio.liveEquityUpToDate = true
    for (const symbol in portfolio.currentSymbols) {
      const positions = portfolio.currentSymbols
      const quote = this.app.store.state.trades.quotes[symbol]
      if (!quote) {
        portfolio.liveEquityUpToDate = false
        liveEquity += positions[symbol].currentValue + positions[symbol].absAcquisitionCost + positions[symbol].acquisitionCost + positions[symbol].eventProfit
      } else {
        const quoteAsset = this.app.store.state.trades.quoteAsset[symbol]
        const currencyFactor = quoteAsset === 'BRL' ? 1 : this.app.store.state.trades.quotes[`${quoteAsset}BRL`] || 1
        let rtCurrentValue = quote * portfolio.currentSymbols[symbol].amount * portfolio.currentSymbols[symbol].multiplier * currencyFactor
        rtCurrentValue += positions[symbol].absAcquisitionCost + positions[symbol].acquisitionCost + positions[symbol].eventProfit
        liveEquity += rtCurrentValue
      }
    }
    Vue.set(state.portfolioList[portfolioId], 'liveEquity', liveEquity)
    const miniChart = state.portfolioList[portfolioId].miniChart
    if (Object.keys(miniChart).length) miniChart[Object.keys(miniChart)[Object.keys(miniChart).length - 1]].equity = liveEquity
    const store = this.app.store
    if (!store.state.trades.portfolioTrades[portfolioId]) return
    Object.values(store.state.trades.portfolioTrades[portfolioId].open).forEach((trade) => {
      store.commit('trades/updateLiveShare', trade._id)
    })
  },
  removePortfolio: (state, portfolio) => {
    Vue.delete(state.portfolioList, portfolio._id)
    Vue.delete(state.idBySlug, portfolio.portfolioSlug)
    Vue.delete(state.slugById, portfolio._id)
    Vue.delete(state.nameBySlug, portfolio.portfolioSlug)
  },
}
