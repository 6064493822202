import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    analysts: {
      'thiago-tavares': {
        _id: '6115a76c4b6916f22f630385',
        miniDescription: 'CNPI-P, CGA e CFA lv3 candidate, combina experiência em buy e sell side.',
        professionalTitle: 'Head de Análise',
        cnpiNumber: 'CNPI-P EM-1828',
        name: 'Thiago Tavares',
        cloudinaryNickname: 'thiago-tavares',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.6,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/thiago-tavares.png',
      },
      'andre-massaro': {
        _id: '6182c712ad4905b215861f63',
        miniDescription: 'CNPI-P e um grande nome do mercado financeiro, com atuação na B3 e Insper.',
        professionalTitle: 'Analista Quantitativo Sênior',
        cnpiNumber: 'CNPI-P EM-2669',
        name: 'André Massaro',
        cloudinaryNickname: 'andre-massaro',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.4,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/andre-massaro.png',
      },
      'hayson-silva': {
        _id: '60f5a6fdf0947b0339998c69',
        miniDescription: 'CNPI-P Especialista em operações de curto prazo com mais de 10 anos de experiência. Pós graduado em Banking pela PUCRS.',
        professionalTitle: 'Analista Técnico',
        cnpiNumber: 'CNPI-P EM-2569',
        name: 'Hayson Silva',
        cloudinaryNickname: 'hayson-silva',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.6,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/hayson-silva.png',
      },
      'victor-lentini': {
        _id: '6180279cad4905b21585e47e',
        miniDescription: 'CNPI-T e especialista de fluxo de negócios, com graduação em economia e pós em finanças pelo Insper.',
        professionalTitle: 'Analista Técnico',
        cnpiNumber: 'CNPI-T EM-2929',
        name: 'Victor Lentini',
        cloudinaryNickname: 'victor-lentini',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.6,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/victor-lentini.png',
      },
      'gabriel-fioravante': {
        _id: '61e058e4c030b0b39013f4a6',
        miniDescription: 'MBA Global Broker IBMEC e entusiasta de criptoativos desde 2016. Foi analista da Nelogica e criou o canal @des.crypto.',
        professionalTitle: 'Especialista em Criptoativos',
        cnpiNumber: null,
        name: 'Gabriel Fioravante',
        cloudinaryNickname: 'gabriel-fioravante',
        imgSrc:
          'https://res.cloudinary.com/portal-do-trader/image/upload/w_370,c_thumb,z_0.55,e_saturation:-100,q_auto:good,dpr_auto,g_face/cdicombr/especialistas/gabriel-fioravante.png',
      },
    },
  }
}

export const state = defaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
