export default function ({ store, route, redirect, query }) {
  const isGuestUser = store.state?.auth.user.hats.guest
  const realm = store.state.realms.activeRealm
  if (realm === 'inter') return
  if (isGuestUser) return
  const loggedIn = store.state.auth.loggedIn
  const isSubscriber = loggedIn ? store.state.auth.user.subscription.active : false
  const userName = loggedIn ? store.state.auth.user.auth.name : null
  const userEmail = loggedIn ? store.state.auth.user.auth.email : null
  const userWhatsapp = loggedIn ? store.state.auth.user.auth.whatsapp : null
  const su = loggedIn ? store.state.auth.user.hats.su : false
  const backUrl = query?.backUrl || route.fullPath
  const subscribedAtLeastOnce = loggedIn ? !!store.state.auth.user.subscription.subscriptionId : false
  if (su) return
  if (loggedIn && (!userName || !userEmail || !userWhatsapp)) redirect({ path: '/checkout', query: { backUrl } })
  if (loggedIn && !isSubscriber && !subscribedAtLeastOnce) redirect({ path: '/checkout', query: { backUrl } })
}
