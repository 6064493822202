import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'waveDots',
  },
  slots: {
    noMore: 'Todos os eventos já foram exibidos',
    noResults: 'Essa sala não possui publicações no feed',
  },
  system: {
    throttleLimit: 500,
  },
})
