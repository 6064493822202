<template>
  <nuxt />
</template>

<script>
import handleHead from '@/components/mixins/handleHead'
export default {
  name: 'EmptyLayout',
  mixins: [handleHead],
}
</script>

<style lang="scss"></style>
