import Vue from 'vue'
import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import { formatTrade, floatingResult } from '~/assets/js/utils'

function defaultState() {
  return {
    preloadedTrades: [],
    portfolioTrades: {},
    floatingResult: {},
    tradesBySymbol: {},
    quotes: {},
    rawQuotes: {},
    quoteAsset: {},
    portfolioByTrade: {},
    subscribedSymbols: {},
    quoteQueue: {},
    quoteInterval: null,
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  receiveTrades(state, trades) {
    let preloadedTradesGoToGarbage = false
    const store = this.app.store
    if (!trades) {
      preloadedTradesGoToGarbage = true
      trades = state.preloadedTrades
    }
    // if (!store.state.auth.loggedIn) return
    trades.forEach((trade) => {
      if (!trade || !trade._id) return
      if (trade.realm && !trade.realm.includes(store.state.realms.activeRealm)) return
      if (trade.invisiblePortfolio && !store.state.auth.user?.hats?.su) return
      if (
        state.portfolioTrades[trade.portfolioId] &&
        state.portfolioTrades[trade.portfolioId].open[trade._id] &&
        state.portfolioTrades[trade.portfolioId].open[trade._id].lastUpdated > trade.lastUpdated
      )
        return
      if (
        state.portfolioTrades[trade.portfolioId] &&
        state.portfolioTrades[trade.portfolioId].closed[trade._id] &&
        state.portfolioTrades[trade.portfolioId].closed[trade._id].lastUpdated > trade.lastUpdated
      )
        return
      trade = formatTrade(trade)
      state.portfolioByTrade[trade._id] = trade.portfolioId
      if (trade.status === 'open' && !state.subscribedSymbols[trade.symbol]) {
        state.subscribedSymbols[trade.symbol] = 1
        if (this.$cedro) this.$cedro.emit('subscribe', { symbol: trade.symbol })
      }
      if (!state.tradesBySymbol[trade.symbol]) state.tradesBySymbol[trade.symbol] = []
      if (trade.status === 'open') {
        if (!state.floatingResult[trade._id]) {
          if (state.quotes[trade.symbol]) state.floatingResult[trade._id] = floatingResult(trade, state.quotes[trade.symbol], state.quotes[trade.quoteAsset])
          else state.floatingResult[trade._id] = trade.result
        }
        if (!state.tradesBySymbol[trade.symbol].includes(trade._id)) state.tradesBySymbol[trade.symbol].push(trade._id)
      } else {
        delete state.floatingResult[trade._id]
        const i = state.tradesBySymbol[trade.symbol].indexOf(trade._id)
        if (i > -1) {
          state.tradesBySymbol[trade.symbol].splice(i, 1)
          state.tradesBySymbol[trade.symbol] = [...state.tradesBySymbol[trade.symbol]]
        }
      }
      if (!state.portfolioTrades[trade.portfolioId]) state.portfolioTrades[trade.portfolioId] = { open: {}, closed: {} }
      if (['waiting', 'open'].includes(trade.status)) {
        state.portfolioTrades[trade.portfolioId].open[trade._id] = { ...trade }
        delete state.portfolioTrades[trade.portfolioId].closed[trade._id]
      } else {
        state.portfolioTrades[trade.portfolioId].closed[trade._id] = { ...trade }
        delete state.portfolioTrades[trade.portfolioId].open[trade._id]
      }
    })
    state.floatingResult = { ...state.floatingResult }
    state.portfolioTrades = { ...state.portfolioTrades }
    Object.keys(state.quotes).forEach((symbol) => {
      store.commit('trades/updateLiveResults', { symbol, last: state.quotes[symbol], forceResultRecalc: true })
    })
    if (preloadedTradesGoToGarbage) state.preloadedTrades = []
  },
  removeTrade: (state, trade) => {
    if (!state.portfolioTrades[trade.portfolioId]) return
    Vue.delete(state.portfolioTrades[trade.portfolioId].open, trade._id)
    Vue.delete(state.portfolioTrades[trade.portfolioId].closed, trade._id)
    Vue.delete(state.portfolioByTrade, trade._id)
    Vue.delete(state.floatingResult, trade._id)
    const i = state.tradesBySymbol[trade.symbol].indexOf(trade._id)
    if (i > -1) {
      state.tradesBySymbol[trade.symbol].splice(i, 1)
      state.tradesBySymbol[trade.symbol] = [...state.tradesBySymbol[trade.symbol]]
    }
  },
  clearClosedTrades: (state, portfolioId) => {
    if (!portfolioId) return
    if (!state.portfolioTrades[portfolioId]?.closed) return
    const top5 = _.keyBy(_.orderBy(Object.values(state.portfolioTrades[portfolioId]?.closed), 'exitTimestamp', 'desc')?.slice(0, 5), '_id')
    if (state.portfolioTrades[portfolioId] && top5.length) Vue.set(state.portfolioTrades[portfolioId], 'closed', top5)
  },
  queueQuote(state, quote) {
    const store = this.app.store
    Vue.set(state.rawQuotes, quote.symbol, {
      last: quote.last,
      ask: quote.ask,
      bid: quote.bid,
      pta: quote.pta,
      status: quote.status,
      groupStatus: quote.groupStatus,
      quoteAsset: quote.quoteAsset,
    })
    if (!state.quoteInterval) {
      state.quoteInterval = setInterval(() => {
        Object.values(state.quoteQueue).forEach((quote) => {
          store.commit('trades/updateLiveResults', quote)
        })
        state.quoteQueue = {}
      }, 5000)
      setTimeout(() => {
        Object.values(state.quoteQueue).forEach((quote) => {
          store.commit('trades/updateLiveResults', quote)
        })
        state.quoteQueue = {}
      }, 1000)
    }
    if (!state.quotes[quote.symbol]) store.commit('trades/updateLiveResults', quote)
    else state.quoteQueue[quote.symbol] = quote
    if (quote.quoteAsset !== 'BRL') {
      if (!state.tradesBySymbol[`${quote.quoteAsset}BRL`]) state.tradesBySymbol[`${quote.quoteAsset}BRL`] = []
      state.tradesBySymbol[quote.symbol].forEach((tradeId) => {
        if (!state.tradesBySymbol[`${quote.quoteAsset}BRL`].includes(tradeId)) state.tradesBySymbol[`${quote.quoteAsset}BRL`].push(tradeId)
      })
      if (!state.subscribedSymbols[`${quote.quoteAsset}BRL`]) {
        state.subscribedSymbols[`${quote.quoteAsset}BRL`] = 1
        if (this.$cedro) this.$cedro.emit('subscribe', { symbol: `${quote.quoteAsset}BRL` })
      }
    }
  },
  updateLiveResults(state, quote) {
    if (!quote.forceResultRecalc && state.quotes[quote.symbol] && state.quotes[quote.symbol] === quote.last) return
    // if (quote.symbol === 'BLUT3') Vue.set(state.quotes, quote.symbol, 1e-64)
    else if (!state.quotes[quote.symbol] || state.quotes[quote.symbol] !== quote.last)
      Vue.set(state.quotes, quote.symbol, quote.last || quote.yesterdayClose || 1e-64)
    if (!state.quoteAsset[quote.symbol]) Vue.set(state.quoteAsset, quote.symbol, quote.quoteAsset || 'BRL')
    if (!state.tradesBySymbol[quote.symbol]) state.tradesBySymbol[quote.symbol] = []
    const affectedPortfolios = []
    const store = this.app.store
    state.tradesBySymbol[quote.symbol].forEach((tradeId) => {
      const trade = state.portfolioTrades[state.portfolioByTrade[tradeId]].open[tradeId]
      if (!trade) return
      if (!affectedPortfolios.includes(state.portfolioByTrade[tradeId])) affectedPortfolios.push(state.portfolioByTrade[tradeId])
      const currencyFactor = trade.quoteAsset === 'BRL' ? 1 : state.quotes[`${trade.quoteAsset}BRL`] || 1
      const result = floatingResult(trade, state.quotes[trade.symbol] || quote.yesterdayClose || 1e-64, currencyFactor)
      Vue.set(state.floatingResult, tradeId, result)
    })
    affectedPortfolios.forEach((portfolioId) => {
      store.commit('portfolios/updateLivePerformance', portfolioId)
    })
  },
  updateLiveShare(state, tradeId) {
    const trade = state.portfolioTrades[state.portfolioByTrade[tradeId]].open[tradeId]
    if (!trade || trade.status !== 'open') return
    const portfolio = this.app.store.state.portfolios.portfolioList[trade.portfolioId]
    if (!portfolio) return
    const { symbol } = trade
    const { acquisitionCost, absAcquisitionCost } = trade.currentSnapshot
    const eventProfit = trade?.currentSnapshot?.assets[symbol]?.eventProfit
    const liveEquity = portfolio.liveEquity
    const liveQuote = state.quotes[symbol] || trade.numeric.exitPrice || trade.numeric.entryPrice
    const currencyFactor = state.quoteAsset[symbol] === 'BRL' ? 1 : state.quotes[`${state.quoteAsset[symbol]}BRL`] || 1
    const liveShare =
      ((liveQuote * trade.numeric.openAmount * trade.multipliers[symbol] * currencyFactor + absAcquisitionCost + acquisitionCost + eventProfit) / liveEquity) *
      100
    Vue.set(state.portfolioTrades[state.portfolioByTrade[tradeId]].open[tradeId], 'liveShare', liveShare)
  },
}
