<template>
  <LoadingScreen v-if="!portfoliosLoaded" />
  <div v-else class="template-wrapper" @scroll.passive="handleScroll">
    <LiveStreamStripe
      v-if="
        inOwnInstances() &&
        isMobile &&
        autoplay &&
        !['aovivo', 'canal-roomSlug', 'minha-assinatura'].includes($route.name) &&
        (!socketWentRogue || !cedroWentRogue)
      "
      :is-mobile="isMobile"
      :autoplay="autoplay"
    />
    <RefreshStripe v-if="socketWentRogue || cedroWentRogue" />

    <PdtNavbar v-if="$activeRealm === 'pdt'" />
    <InterNavbar v-else-if="$activeRealm === 'inter'" />
    <Navbar v-else />
    <PdtBanner v-if="$route.path === '/aovivo' && pdtBanner.enabled" />
    <b-container
      class="d-flex px-0 mx-lg-auto"
      :class="[`${$activeRealm}-container`, $activeRealm === 'inter' && $route.name !== 'canal-roomSlug' && 'mb-5 mb-xl-0']"
      fluid
    >
      <portfolio-sidebar />
      <nuxt id="cdi_container" />
    </b-container>
    <ModalsController />
    <FloatingVideo v-if="['cdi'].includes($activeRealm)" :show-floating-video="showFloatingVideo" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { gsap } from 'gsap'
import _ from 'lodash'
import moment from 'moment'
import handleSubscription from '@/components/mixins/handleSubscription'
import handleHead from '@/components/mixins/handleHead'

import { toastConfig } from '~/assets/js/toast'
import { objectId } from '~/assets/js/utils'

export default {
  name: 'CdiLayout',
  components: {
    Navbar: () => import('@/components/navbar/index'),
    PdtNavbar: () => import('@/components/pdt/navbar/index'),
    InterNavbar: () => import('@/components/inter/navbar/index'),
    ModalsController: () => import('@/components/modals/ModalsController'),
    PortfolioSidebar: () => import('@/components/sidebar/PortfolioSidebar'),
    FloatingVideo: () => import('@/components/floating-video/FloatingVideo'),
    LoadingScreen: () => import('@/components/UI/LoadingScreen'),
    RefreshStripe: () => import('~/components/UI/RefreshStripe'),
    LiveStreamStripe: () => import('~/components/UI/LiveStreamStripe'),
    PdtBanner: () => import('@/components/pdt/PdtBanner'),
  },
  mixins: [handleHead, handleSubscription],
  middleware: ['authenticated', 'handleMissingSignupData', 'redirectPdtUser'],
  data() {
    return {
      liveSection: false,
      lastDragTarget: null,
      adjustSidebar: false,
      isMobile: false,
      socketQsl: true,
      cedroQsl: true,
      qslInProgress: false,
      host: null,
    }
  },
  computed: {
    ...mapFields(['initialized', 'socketWentRogue', 'socketReconnectionAttempts', 'cedroWentRogue', 'cedroReconnectionAttempts']),
    ...mapFields('portfolios', ['portfolioList', 'portfoliosLoaded']),
    ...mapFields('trades', ['portfolioTrades', 'subscribedSymbols']),
    ...mapFields('auth', ['user.subscription.subscriptionId']),
    ...mapFields('upload', ['userIsDraggingAFile', 'files']),
    ...mapFields('ao_vivo/stream', ['socketCount', 'autoplay']),
    ...mapFields('ao_vivo/chat', ['votesValidUntil', 'voteWindowInterval']),
    ...mapFields('forms', ['lightning']),
    ...mapFields('realms', ['realms']),
    ...mapFields('rooms', ['rooms']),
    ...mapGetters('realms', ['hostname2realm', 'isCdiInstance', 'inOwnInstances']),
    ...mapFields('banner', { pdtBanner: 'banner' }),
    ...mapFields('forms', ['setDelayAlert']),
    // sentryUserData() {
    //   const { portfolios, ...sentryUserData } = this.$auth.user
    //   return { ...sentryUserData, 'portfolios.following': portfolios.following }
    // },
    showFloatingVideo() {
      if (this.$activeRealm === 'pdt') return !['/seguindo', '/minha-assinatura'].includes(this.$route.path)
      return !['/aovivo', '/seguindo', '/minha-assinatura'].includes(this.$route.path)
    },
  },
  watch: {
    setDelayAlert(state) {
      this.createDelayToast(state)
    },
    $route(from, to) {
      if (['cdi', 'pdt'].includes(this.$activeRealm)) return
      if (to.path !== from.path) this.$cookies.remove('roomFirstAccess')
    },
  },
  beforeMount() {
    if (this.initialized) return
    this.initialized = true
    if (!this.subscriptionId) this.validateVoucher()
    window.addEventListener('dragenter', this.handleDragEnter)
    window.addEventListener('dragleave', this.handleDragLeave)
    window.addEventListener('dragover', this.handleDragOver)
    window.addEventListener('drop', this.handleDrop)
    window.addEventListener('focus', this.handleFocus)
    if (this.$activeRealm === 'inter') this.setInterFonts()
    this.$cedro.open()
    this.$socket.open()
    this.$socket.on('force-refresh', (time) => setTimeout(() => location.reload(), Math.random() * (time || 30000)))
    this.$socket.on('hi', () => this.$socket.emit('hi'))
    this.$socket.on('qsl', () => (this.socketQsl = true))
    this.$cedro.on('qsl', () => (this.cedroQsl = true))
    this.$socket.on('connect', () => {
      this.$axios.get('/version').then((res) => {
        if (res.data !== this.$config.commitHash) setTimeout(() => location.reload(), Math.random() * 30000)
      })
      this.socketReconnectionAttempts = 0
      this.socketWentRogue = false
      this.socketQsl = true
      if (this.$store.getters['auth/hat']('cnpi')) {
        try {
          const boletaLightningCookie = this.$cookies.get('cdi_boleta_lightning')
          if (boletaLightningCookie) this.lightning.cookie = { ...this.lightning.cookie, ...boletaLightningCookie }
          else this.$cookies.set('cdi_boleta_lightning', this.lightning.cookie)
        } catch (err) {
          this.$cookies.set('cdi_boleta_lightning', this.lightning.cookie)
        }
      }
    })
    this.$socket.on('disconnect', (reason) => {
      this.socketReconnection()
    })
    this.$cedro.on('connect', () => {
      this.cedroReconnectionAttempts = 0
      this.cedroWentRogue = false
      this.cedroQsl = true
      if (this.$auth.loggedIn && !this.$auth.user.hats.guest) this.$cedro.emit('i-am-user')
      Object.keys(this.subscribedSymbols).forEach((symbol) => this.$cedro.emit('subscribe', { symbol }))
    })
    this.$cedro.on('disconnect', (reason) => {
      this.cedroReconnection()
    })
    this.$socket.on('trades', (data) => {
      this.receiveTrades(data)
    })
    this.$socket.on('portfolios', (data) => {
      this.receivePortfolios(data)
    })
    this.$socket.on('toast', (message) => {
      if (!['aovivo', 'canal-roomSlug'].includes(this.$route.name)) return
      if (message.realm !== this.$activeRealm) return
      this.$toast[message.type](message.text, toastConfig(message.config))
    })
    this.$socket.on('notifications', (notifications) => this.receiveNotifications(notifications))
    this.$socket.on('feed', (feed) => {
      if (!feed || !feed[0]) return
      this.receiveFeeds(feed)
      // if (feed.length === 1) {
      //   const portfolios = feed[0].content.portfolios
      //   const feedId = feed[0]._id
      //   this.$nuxt.$emit('new-feed', { feedId, portfolios })
      // }
    })
    this.$socket.on('remove-feed', (feedId) => {
      this.removeFeed(feedId)
      this.$nuxt.$emit('event-removed', feedId)
    })
    this.$socket.on('messages', (messages) => this.receiveMessages(messages))
    this.$socket.on('chat-votes', (votes) => this.receiveVotes(votes))
    this.$socket.on('events', (events) => this.receiveEvents(events))
    this.$socket.on('remove-message', (data) => this.removeMessage(data.messageId))
    this.$socket.on('remove-event', (data) => this.removeEvent(data.eventId))
    this.$socket.on('remove-portfolio', (portfolio) => {
      if (this.$route.params.portfolioSlug === portfolio.portfolioSlug)
        this.$router.push({ path: '/carteiras' }, () => {
          this.removePortfolio(portfolio)
        })
    })
    this.$socket.on('remove-notifications', (notification) => this.removeNotifications(notification))
    this.$socket.on('remove-trade', (trade) => this.removeTrade(trade))
    this.$socket.on('refresh-user', (user) => {
      if (!this.$auth.loggedIn || this.$auth.user._id !== user._id) return
      this.$auth.setUser(user)
      const { voucher, _ga } = this.$cookies.getAll()
      if (user.flags.voucherCode && user.flags.voucherCode !== voucher) this.$cookies.set('voucher', user.flags.voucherCode)
      if (user.insights._ga && user.insights._ga !== _ga) this.$cookies.set('_ga', user.insights._ga)
    })
    this.$socket.on('set-stream', (stream) => {
      if (stream.lastErrorObject) stream = stream.value
      this.updateStream(stream)
      this.$bvModal.hide('stream_admin')
    })
    this.$socket.on('banner', (banner) => {
      this.updateBanner(banner)
      this.$bvModal.hide('stream_admin')
    })
    this.$socket.on('socket-count', (socketCount) => (this.socketCount = socketCount))
    this.$socket.on('room-socket-count', (room) => {
      if (room.realm !== this.$activeRealm) return
      this.rooms[this.$activeRealm].forEach((localRoom) => {
        if (localRoom._id !== room.room) return
        localRoom.socketCount = room.socketCount
      })
    })
    this.$cedro.on('live-quote', (quote) => this.queueQuote(quote))
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => document.body.removeAttribute('style'))

    this.cookieVoucherCode = this.$cookies.get('voucher')
    this.cookie499Expiry = this.$cookies.get('499Expiry')
    // this.$sentry.setUser({ username: this.$auth.user.auth.name, email: this.$auth.user.auth.email })
    // this.$sentry.setContext('User Data', this.sentryUserData)
  },
  mounted() {
    setTimeout(() => {
      const _id = objectId(new Date())
      this.receiveMessages([
        {
          _id,
          avatar: {
            nickname: 'Mercado ao Vivo',
            color: 1,
          },
          author: {
            moderator: true,
          },
          text: 'Bem-vindo ao ambiente de trading ao vivo da Inter Invest. Acompanhe as transmissões ao vivo e tire suas dúvidas pelo chat.\nProgramação: Segunda à Sexta das 8:30 até 17:00 com Caio Sasaki (Portal do Trader), Fabrício Stagliano (Team Bullbear) e LVL Trading.',
          realm: 'inter',
          room: 'sala-inter',
        },
      ])
    }, 10000)
    this.isMobile = document.documentElement.clientWidth < 1200
    gsap.config({ nullTargetWarn: false })

    this.$nextTick(() => {
      window.addEventListener('resize', this.mobileDetection)
      this.receivePortfolios()
      this.portfoliosLoaded = true
      this.receiveTrades()
    })

    this.$nuxt.$on('new-voucher-code', (voucher) => (this.cookieVoucherCode = voucher))
    this.$nuxt.$on('499-expiry', (expiry) => (this.cookie499Expiry = expiry))
    if (this.has499Voucher && !this.has499Expiry) this.$cookies.remove('voucher')

    this.$nuxt.$on('remove-499-data', () => {
      if (this.has499Expiry) {
        this.$cookies.remove('499Expiry')
        this.cookie499Expiry = null
      }
      if (this.has499Voucher) {
        this.$cookies.remove('voucher')
        this.cookieVoucherCode = null
      }
    })
    window.onblur = () => {
      if (!['aovivo', 'canal-roomSlug'].includes(this.$route.name)) return
      const toastsContainer = document.querySelector('.toasted-container')
      if (toastsContainer) toastsContainer.style.opacity = 0
    }
    window.onfocus = () => {
      if (!['aovivo', 'canal-roomSlug'].includes(this.$route.name)) return
      this.$toast?.toasts.forEach((t) => t.remove())
      const toastsContainer = document.querySelector('.toasted-container')
      if (toastsContainer) toastsContainer.style.opacity = 1
    }
    if (!this.voteWindowInterval)
      this.voteWindowInterval = setInterval(() => {
        if (moment().valueOf() > this.votesValidUntil) this.votesValidUntil += 24 * 60 * 60 * 1000
      }, 10000)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.mobileDetection)
    window.removeEventListener('focus', this.handleFocus)
    this.$socket.removeAllListeners()
    this.$socket.disconnect()
    this.$cedro.removeAllListeners()
    this.$cedro.disconnect()
  },

  methods: {
    ...mapMutations({
      setVoucherData: 'voucherData/setVoucherData',
      updateStream: 'ao_vivo/stream/updateStream',
      updateBanner: 'banner/updateBanner',
      receiveVotes: 'ao_vivo/chat/receiveVotes',
      receiveMessages: 'ao_vivo/chat/receiveMessages',
      removeFeed: 'feed/removeFeed',
      receiveFeeds: 'feed/receiveFeeds',
      receiveNotifications: 'notifications/receiveNotifications',
      removeNotifications: 'notifications/removeNotifications',
      receiveEvents: 'ao_vivo/events/receiveEvents',
      removeMessage: 'ao_vivo/chat/removeMessage',
      removeEvent: 'ao_vivo/events/removeEvent',
      receivePortfolios: 'portfolios/receivePortfolios',
      removePortfolio: 'portfolios/removePortfolio',
      receiveTrades: 'trades/receiveTrades',
      removeTrade: 'trades/removeTrade',
      queueQuote: 'trades/queueQuote',
      setPushNotificationsSupport: 'setPushNotificationsSupport',
      setPushNotificationsStatus: 'setPushNotificationsStatus',
      setPushNotificationsPermission: 'setPushNotificationsPermission',
    }),
    handleDragEnter(e) {
      if (!document.querySelectorAll('.modal.show').length && e.dataTransfer.types[0] === 'Files') {
        this.lastDragTarget = e.target
        this.userIsDraggingAFile = true
      }
    },
    handleDragLeave(e) {
      if (!document.querySelectorAll('.modal.show').length && e.dataTransfer.types[0] === 'Files') {
        if (e.target === this.lastDragTarget || e.target === document) this.userIsDraggingAFile = false
      }
    },
    handleDragOver(e) {
      if (!document.querySelectorAll('.modal.show').length && e.dataTransfer.types[0] === 'Files') e.preventDefault()
    },
    handleDrop(e) {
      if (e.target.classList.contains('message-input')) return e.preventDefault()
      if (!document.querySelectorAll('.modal.show').length && e.dataTransfer.types[0] === 'Files') {
        e.preventDefault()
        this.userIsDraggingAFile = false
        if (!e.target.classList.contains('drop-zone')) return
        this.files = [e.dataTransfer.files[0]]
      }
    },
    handleFocus() {
      if (this.qslInProgress) return
      this.socketQsl = false
      this.cedroQsl = false
      this.qslInProgress = true
      this.$socket.emit('qsl')
      this.$cedro.emit('qsl')
      setTimeout(() => {
        this.qslInProgress = false
        if (!this.socketQsl && this.$socket.connected) this.$socket.disconnect()
        if (!this.cedroQsl && this.$cedro.connected) this.$cedro.disconnect()
      }, 5000)
    },
    handleScroll() {
      if (this.$el.scrollTop > 0 && !this.adjustSidebar && !this.isMobile) {
        this.adjustSidebar = true
        this.$nuxt.$emit('adjust-sidebar')
      }

      if (this.$el.scrollTop < 55 || this.isMobile) {
        this.adjustSidebar = false
        this.$nuxt.$emit('remove-sidebar-adjustment')
      }
    },
    mobileDetection: _.debounce(function () {
      this.isMobile = document.documentElement.clientWidth <= 1200
      this.$nuxt.$emit('detect-screen-width', this.isMobile)
    }, 50),
    socketReconnection() {
      return setTimeout(() => {
        if (this.$socket.connected) return
        if (this.socketWentRogue) return
        if (this.socketReconnectionAttempts >= 5) return (this.socketWentRogue = true)
        this.socketReconnectionAttempts++
        this.$socket.connect()
        setTimeout(() => this.socketReconnection(), 2500)
      }, 500 * this.socketReconnectionAttempts + Math.random() * 1000)
    },
    cedroReconnection() {
      return setTimeout(() => {
        if (this.$cedro.connected) return
        if (this.cedroWentRogue) return
        if (this.cedroReconnectionAttempts >= 5) return (this.cedroWentRogue = true)
        this.cedroReconnectionAttempts++
        this.$cedro.connect()
        setTimeout(() => this.cedroReconnection(), 2500)
      }, 500 * this.cedroReconnectionAttempts + Math.random() * 1000)
    },
    async validateVoucher() {
      try {
        if (this.voucherCode) return
        const voucherCode = await this.$cookies.get('voucher')
        if (voucherCode) {
          const validVoucher = await this.$axios.get(`/api/billing/voucher?voucherCode=${voucherCode}`)
          this.setVoucherData({ voucherCode, ...validVoucher?.data })
        }
      } catch (error) {
        console.log(error)
      }
    },
    createDelayToast(setDelayAlert) {
      if (!setDelayAlert) return this.delayAlertTimeout && clearTimeout(this.delayAlertTimeout)

      this.delayAlertTimeout = setTimeout(() => {
        const el = this.$createElement
        const id = `delay-alert-toast-` + new Date().valueOf()
        const content = el('span', { class: ['semibold'] }, 'O serviço de cotações está demorando mais que o esperado. Por favor, aguarde.')
        // const divider = el('hr', { class: ['w-100', 'my-2'] })
        // const $closeButton = el('b-button', { on: { click: () => this.$bvToast.hide(id) }, props: { variant: 'danger' }, class: ['bold'] }, 'Entendi')
        this.$bvToast.toast([content], {
          id,
          title: 'Atraso nas Operações',
          variant: 'danger',
          solid: true,
          // noCloseButton: true,
          // noAutoHide: true,
        })
      }, 5000)
    },
    setInterFonts() {
      document.getElementsByTagName('html')[0].classList.add('inter')
    },
  },
}
</script>

<style lang="scss" scoped>
html,
body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  span {
    font-family: $cdi-font-headings !important;
  }
}
/* html.inter {
  body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    span {
      font-family: $inter-body !important;
      color: $inter-black;
      line-height: 1.67;
      letter-spacing: 0.3px;
      @include media-breakpoint-up(md) {
        line-height: 17px !important;
      }
      @include media-breakpoint-up(lg) {
        line-height: 22px !important;
      }
    }
  }
}

.template-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  background: $cdi-gray-background;
  @include scroll-bar();

  .inter-container {
    background-color: white;
  }
}
*/
</style>
