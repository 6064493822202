import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    // streamInfo
    videoTitle: 'Day Trade | Carteira do Investidor',
    videoId: '',
    autoplay: false,
    privateLiveStream: false,
    socketCount: 0,
    // overlay
    overlay: {
      text: 'Voltamos amanhã as 8h45 com o Call de Notícias!',
      active: true,
    },
  }
}

export const state = defaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  updateStreams(state, streams) {
    streams.forEach((stream) => this.commit('ao_vivo/stream/updateStream', stream))
  },
  updateStream(state, stream) {
    const store = this.app.store
    const realm = store.state.realms.activeRealm
    if (!stream || stream.realm !== realm) return

    const activeRoom = store.state.rooms.activeRoom
    const rooms = store.state.rooms.rooms[realm]
    rooms.forEach((room) => {
      if (room._id !== stream.room) return
      room.stream = {
        videoId: stream.videoId,
        autoplay: stream.autoplay,
        videoTitle: stream.videoTitle,
        overlay: stream.overlay,
        privateLiveStream: stream.privateLiveStream,
      }
    })
    store.state.rooms.rooms = { ...store.state.rooms.rooms }
    if (stream.room === activeRoom) this.commit('ao_vivo/stream/changeRoomStream')
  },
  changeRoomStream(state) {
    const store = this.app.store
    const realm = store.state.realms.activeRealm

    const activeRoom = store.state.rooms.activeRoom
    const rooms = store.state.rooms.rooms[realm]
    rooms.forEach((room) => {
      if (room._id !== activeRoom) return
      if (!room.stream) return
      Object.keys(room.stream).forEach((key) => {
        state[key] = room.stream[key]
      })
    })
  },
}
