import { getField, updateField } from 'vuex-map-fields'

const realmDomains = {
  'pdt.cdi.com.br': 'pdt',
  'app.portaldotrader.com.br': 'pdt',
  'inter.cdi.com.br': 'inter',
  'mercadoaovivo.bancointer.com.br': 'inter',
  'smarttinvest.cdi.com.br': 'smarttinvest',
  'cdi.com.br': 'cdi',
  // ngrok links for testing
  // '98bf-179-110-188-4.ngrok-free.app': 'pdt',
}

function defaultState() {
  return {
    activeRealm: null,
    realms: {
      cdi: {
        name: 'Carteira do Investidor',
        displayGlobalEventsFromOtherRealms: true,
      },
      pdt: {
        name: 'Portal do Trader',
        displayGlobalEventsFromOtherRealms: true,
      },
      inter: {
        name: 'Banco Inter',
        displayGlobalEventsFromOtherRealms: false,
      },
      smarttinvest: {
        name: 'Smarttinvest',
        displayGlobalEventsFromOtherRealms: false,
      },
    },
  }
}

export const state = defaultState

export const getters = {
  getField,
  hostname2realm: (state) => (hostname) => {
    if (realmDomains[hostname]) return realmDomains[hostname]
    const subdomain = hostname.split('.')[0]
    if (Object.keys(state.realms).includes(subdomain)) return subdomain
    return Object.keys(state.realms)[0]
  },
  isCdiInstance: (state) => () => {
    return state.activeRealm === 'cdi'
  },
  isPdtInstance: (state) => () => {
    return state.activeRealm === 'pdt'
  },
  isInterInstance: (state) => () => {
    return state.activeRealm === 'inter'
  },
  isPortfolioAsModal: (state) => () => {
    return ['inter'].includes(state.activeRealm)
  },
  inOwnInstances: (state) => () => {
    return ['pdt', 'cdi'].includes(state.activeRealm)
  },
  instanceName: (state) => () => {
    return state.realms[state.activeRealm]?.name || 'Carteira do Investidor'
  },
  instanceAllowsVoting: (state) => () => {
    return ['pdt', 'cdi', 'inter'].includes(state.activeRealm)
  },
  instanceAllowsPrivatePortfolio: (state, getters, rootState) => () => {
    return ['pdt'].includes(state.activeRealm) && rootState.auth.user.hats.amateurAnalyst
  },
  instanceAllowsChangeAvatar: (state) => () => {
    return ['pdt'].includes(state.activeRealm)
  },
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
