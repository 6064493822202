import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    files: [],
    avatarFile: [],
    userIsDraggingAFile: false,
    uploadInProgress: false,
    uploadPercentage: 0,
  }
}

export const state = defaultState

export const getters = { getField }

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
