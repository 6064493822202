<template>
  <client-only>
    <div class="min-vh-100">
      <nuxt />
    </div>
  </client-only>
</template>

<script>
import handleHead from '@/components/mixins/handleHead'
export default {
  mixins: [handleHead],
  data() {
    return {
      liveSection: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
