<template>
  <div class="template-wrapper">
    <b-container class="cdi-container px-0 mx-lg-auto" fluid>
      <nuxt />
    </b-container>
  </div>
</template>

<script>
import handleHead from '@/components/mixins/handleHead'
export default {
  mixins: [handleHead],
}
</script>

<style lang="scss" scoped>
.template-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  background: $cdi-gray-background;
  @include scroll-bar(5px);
}
.cdi-container {
  max-width: 1920px;
}
</style>
