import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    notifications: {},
    references: [
      { text: '30 Dias', value: [30, 'day'] },
      { text: '3 Meses', value: [3, 'month'] },
      { text: '6 Meses', value: [6, 'month'] },
      { text: '12 Meses', value: [12, 'month'] },
    ],
  }
}

export const state = defaultState

export const getters = { getField }

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
