import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    activeRoom: null,
    activeRoomTab: null,
    rooms: {
      cdi: [
        {
          _id: 'main',
          owner: 'André Massaro',
          ownerCloudinaryAvatar: 'andre-massaro',
          roomName: 'Carteira do Investidor',
          tags: ['Day Trade', 'Swing Trade'],
          description: 'Operarando Day Trade, Swing Trade e investir a longo prazo, nosso time de traders ensina as principais técnicas utilizadas no mercado.',
          socketCount: 0,
          isWatching: false,
          roomImgSlug: 'caio-sasaki_inter_analyst',
          roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/caio-sasaki_inter_analyst',
        },
      ],
      pdt: [
        {
          _id: 'main',
          owner: 'Caio Sasaki',
          ownerCloudinaryAvatar: 'caio-sasaki',
          roomName: 'Portal do Trader',
          tags: ['Day Trade', 'Swing Trade'],
          description: 'Operarando Day Trade, Swing Trade e investir a longo prazo, nosso time de traders ensina as principais técnicas utilizadas no mercado.',
          socketCount: 0,
          isWatching: false,
          roomImgSlug: 'caio-sasaki_inter_analyst',
          roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/caio-sasaki_inter_analyst',
        },
      ],
      inter: [
        // {
        //   _id: 'portal-do-trader',
        //   owner: 'Caio Sasaki',
        //   ownerCloudinaryAvatar: 'caio-sasaki',
        //   roomName: 'Portal do Trader',
        //   tags: ['Day Trade', 'Swing Trade'],
        //   description:
        //     'Operações ao vivo de Day Trade e Swing Trade usando gráficos e fluxo. Nosso time de especialistas mostra em tempo real  as melhores técnicas para conseguir resultados no mercado.',
        //   socketCount: 0,
        //   isWatching: false,
        //   liveDays: 'Segunda a Sexta',
        //   livesSchedule: ['8:30 - 12:00', '15:00 - 16:00'],
        //   roomImgSlug: 'caio-sasaki_inter_analyst',
        //   roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/caio-sasaki_inter_analyst',
        // },
        // {
        // _id: 'top-gain',
        // owner: 'Alison Correia',
        // ownerCloudinaryAvatar: 'alison-correia',
        // roomName: 'Top Gain',
        // tags: ['Day Trade', 'Swing Trade'],
        // description: 'Com linguagem simples e objetiva nossa programação é pensada para você que deseja se aprofundar e conhecer o mercado financeiro.',
        // socketCount: 0,
        // isWatching: false,
        // liveDays: 'Segunda a Sexta',
        // livesSchedule: ['8:30 - 18:00'],
        // roomImgSlug: 'alison-correia_inter_analyst',
        // roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/alison-correia_inter_analyst',
        // },
        // {
        //   _id: 'team-bullbear',
        //   owner: 'Fabricio Stagliano',
        //   ownerCloudinaryAvatar: 'fabricio-stagliano',
        //   roomName: 'Team Bullbear',
        //   tags: ['Day Trade', 'Swing Trade'],
        //   description:
        //     'Fabrício Stagliano é analista CNPI, trader, professor e palestrante. Especialista em renda variável, análise gráfica, operações daytrade, mercado futuro e derivativos.',
        //   socketCount: 0,
        //   isWatching: false,
        //   liveDays: 'Terça a Quinta',
        //   livesSchedule: ['9:30 - 12:00'],
        //   roomImgSlug: 'fabricio_stagliano_inter_analyst',
        //   roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/fabricio_stagliano_inter_analyst',
        // },
        // {
        //   _id: 'lvl-trading',
        //   owner: 'Robert Lima',
        //   ownerCloudinaryAvatar: 'robert-lima',
        //   roomName: 'LVL Trading',
        //   tags: ['Day Trade', 'Swing Trade'],
        //   description:
        //     'Ao vivo no Day Trade de ações com os Analistas e Traders da LVL Trading e oportunidades no Swing Trade com análise do time da Sonar Trade.',
        //   socketCount: 0,
        //   isWatching: false,
        //   liveDays: 'Segunda a Sexta',
        //   livesSchedule: ['8:50 - 11:00'],
        //   roomImgSlug: 'robert-lima_inter_analyst',
        //   roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/robert-lima_inter_analyst',
        // },
        // {
        // _id: 'team-urso',
        // owner: 'RafaTrader',
        // ownerCloudinaryAvatar: 'rafa-trader',
        // roomName: 'Team Urso',
        // tags: ['Day Trade', 'Swing Trade'],
        // description: 'O Rafa trader estará junto de você diariamente estudando, operando, trocando informações pertinentes do mercado.',
        // socketCount: 0,
        // isWatching: false,
        // roomImgSlug: 'rafa-trader_inter_analyst',
        // roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/rafa-trader_inter_analyst',
        // },
        {
          _id: 'sala-inter',
          owner: 'Caio Sasaki, Fabricio Stagliano, Portal do Trader e LVL Trading',
          ownerCloudinaryAvatar: 'caio-sasaki',
          roomName: 'Sala Inter',
          tags: ['Day Trade', 'Swing Trade'],
          description:
            'Operações ao vivo de Day Trade e Swing Trade usando gráficos e fluxo. Nosso time de especialistas mostra em tempo real as melhores técnicas para conseguir resultados no mercado.',
          socketCount: 0,
          isWatching: false,
          liveDays: 'Segunda a Sexta',
          livesSchedule: ['8:30 - 18:00'],
          roomImgSlug: 'caio-sasaki_inter_analyst',
          roomImg: 'https://res.cloudinary.com/portal-do-trader/image/upload/dpr_auto,q_auto:eco,w_125/inter/analysts/caio-sasaki_inter_analyst',
        },
      ],
    },
  }
}

export const state = defaultState

export const getters = { getField }

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
