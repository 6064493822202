import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { dateFromObjectId, formatTrade } from '~/assets/js/utils'

function defaultState() {
  return {
    feeds: {},
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

export const mutations = {
  updateField,
  receiveFeeds(state, feeds) {
    const store = this.app.store
    const activeRealm = store.state.realms.activeRealm
    const portfolioList = store.state.portfolios.portfolioList
    const following = store.state.auth.user.portfolios.following

    const realmPortfolioIds = Object.keys(portfolioList)
    const realmPortfolios = realmPortfolioIds.filter((pid) => portfolioList[pid]?.realm?.includes(activeRealm))
    const roomSlug = this.app.router.history.current.params.roomSlug || 'main'
    const roomPortfolios = realmPortfolios.filter((pid) => portfolioList[pid]?.room?.[activeRealm]?.includes(roomSlug))
    const exceptionRoute = this.app.router.history.current.path === '/feed'

    const cleanFeedList = feeds.filter((feed) => {
      if (!exceptionRoute && ['trade', 'overview'].includes(feed.type)) return false
      const feedPortfolios = feed.content.portfolios.reduce((arr, p) => {
        arr.push(p.portfolioId)
        return arr
      }, [])
      if (feedPortfolios.length) return feedPortfolios.some((p) => following.includes(p) || roomPortfolios.includes(p))
      return feed.content.rooms?.includes(`${activeRealm}.${roomSlug}`)
    })

    cleanFeedList.forEach((feed) => {
      if (!feed || !feed._id) return
      feed = {
        ...feed,
        timestamp: dateFromObjectId(feed._id),
      }

      if (!feed.author) {
        feed.author = {
          name: 'Carteira do Investidor',
          nickname: 'cdi',
          cloudinaryNickname: 'cdi',
        }
      }

      if (feed.content.trade) feed.content.trade = formatTrade(feed.content.trade)

      if (feed.content.event === 'portfolio-update') {
        if (feed.content.newTrades) {
          feed.content.newTrades.forEach((newTrade) => {
            newTrade.content.trade = formatTrade(newTrade.content.trade)
          })
        }
      }
      if (!feed.author.cloudinaryNickname) feed.author.cloudinaryNickname = 'cdi'
      if (!feed.comments) feed.comments = []

      state.feeds[feed._id] = feed
    })

    state.feeds = { ...state.feeds }
  },
  removeFeed: (state, feedId) => {
    Vue.delete(state.feeds, feedId)
  },
  resetState(state) {
    Object.assign(state, defaultState())
  },
}
