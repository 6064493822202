import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    initialized: false,
    socketWentRogue: false,
    cedroWentRogue: false,
    socketReconnectionAttempts: 0,
    cedroReconnectionAttempts: 0,
    loginModalMode: 'login',
    mobileDisplay: false,
    isPushNotificationsActive: null,
    isPushNotificationsSupported: null,
    pushNotificationsPermission: null,
  }
}

export const state = defaultState

export const getters = {
  getField,
  shouldMergeInterAndPdt: (state) => () => {
    const now = moment()
    if (state.interPdtConnectionWindow.constructor === Array) {
      return state.interPdtConnectionWindow.some((interPdtConnectionWindow) => {
        const periodStart = moment(interPdtConnectionWindow.start, 'HHmmss')
        const periodEnd = moment(interPdtConnectionWindow.end, 'HHmmss')

        return now.isBetween(periodStart, periodEnd)
      })
    }
    const periodStart = moment(state.interPdtConnectionWindow.start, 'HHmmss')
    const periodEnd = moment(state.interPdtConnectionWindow.end, 'HHmmss')

    return now.isBetween(periodStart, periodEnd)
  },
  getRoomPairs: (state, getters) => (realm, room) => {
    const pairs = [`${realm}.${room}`]
    if (getters.shouldMergeInterAndPdt()) {
      if (realm === 'inter' && room === 'sala-inter') pairs.push('pdt.main')
    }

    return pairs
  },
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  setPushNotificationsStatus: (state, isEnabled) => {
    state.isPushNotificationsActive = isEnabled
  },
  setPushNotificationsPermission: (state, permission) => {
    state.pushNotificationsPermission = permission
  },
  setPushNotificationsSupport: (state, isSupported) => {
    state.isPushNotificationsSupported = isSupported
  },
}

export const strict = false
