<template>
  <client-only>
    <div class="min-vh-100">
      <navbar />
      <nuxt />
      <ModalsController />
    </div>
  </client-only>
</template>

<script>
import Navbar from '@/components/navbar/index'
import ModalsController from '@/components/modals/ModalsController'
import handleHead from '@/components/mixins/handleHead'

export default {
  components: {
    Navbar,
    ModalsController,
  },
  mixins: [handleHead],
  data() {
    return {
      liveSection: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
